@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import "generic/reset";
@import "base/fonts";
@import "tools/tools-mixins-form";
@import "tools/tools-only";
@import "tools/selectric";
@import "tools/vue-select";
@import "modules/experience";
@import "modules/mainbanner";
@import "modules/profileteam";
@import "modules/prizelist";
@import "modules/historia";
@import "modules/chainheader";
@import "modules/profilepage";
@import "modules/ourteam";
@import "modules/operationtabs";
@import "modules/news";
@import "modules/trabalhe-conosco";
@import "modules/mvv";

.modal {
    display: none;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 1) url("../assets/img/loading.gif") 50% 50% no-repeat;
}

/* enquanto estiver carregando, o scroll da página estará desativado */

body.loading {
    overflow: hidden;
}

/* a partir do momento em que o body estiver com a classe loading,  o modal aparecerá */

body.loading .modal {
    display: block;
}

%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.section--about {
    position: relative;
    bottom: 43px;
    z-index: 1;

    .experience {
        &--home {
            @media (min-width: 1024px) {
                background-image: url("../assets/img/img-bg-static-rede.jpg");
                background-repeat: no-repeat;
                background-size: cover;

                .experience__content {
                    background-color: transparent;

                    .container {
                        @media (min-width: 1024px) {
                            padding-right: 12.7%;
                            padding-left: 12.7%;
                        }
                    }
                }
            }
        }

        @media (min-width: 1024px) {
            margin-top: 43px;
        }
    }
}

.container-swiper {
    padding: 52px 23px;
}

.container {
    padding: 52px 0;

    .newsletter {
        display: block;

        @media (min-width: 768px) {
            top: 9px;
            position: relative;
        }

        &__img {
            top: 7px;
            display: inline-block;
            position: relative;
            margin-right: 13px;

            @media (min-width: 768px) {
                top: 0;
            }
        }

        &__title {
            display: inline-block;
            font-family: "setimo-bold";
            font-size: 15px;
            font-weight: bold;
            line-height: 1;
            text-transform: uppercase;
            color: #ffffff;

            @media (min-width: 768px) {
                font-size: 14px;
                line-height: 1.14;
                width: 150px;
                letter-spacing: 2.2px;
            }
        }

        &__form-newsletter {
            display: block;
            margin-top: 31px;

            @media (min-width: 768px) {
                display: inline;
                top: -11px;
                position: relative;
            }

            .input-control {
                width: 170px;
                height: 45px;
                border-radius: 4px;
                box-sizing: border-box;
                background: #fff;
                display: inline;
                border: 0;
                padding-left: 13px;
                font-family: "Proxima_Nova_Sb";
                font-size: 14px;
                font-weight: 600;

                @media (min-width: 414px) {
                    width: 264px;
                }

                @media (min-width: 768px) {
                    width: 231px;
                }

                @media (min-width: 1024px) {
                    width: 314px;
                }
            }
        }
    }

    &-team {
        padding-top: 0;
    }

    &--smaller {
        padding-top: 30px;
        padding-bottom: 30px;

        @media (min-width: 768px) {
            display: inline-block;
            padding: 0px !important;
        }
    }

    @media (max-width: 767px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (min-width: 1024px) {
        padding: 91px 0 0;
    }

    &--operation-intro {
        box-sizing: border-box;
        overflow: hidden;
        padding-top: 48px;
        padding-bottom: 52px;

        @media (min-width: 1024px) {
            padding-top: 68px;
            padding-bottom: 0;
        }
    }

    &--operation-tabs {
        display: none;

        @media (min-width: 1024px) {
            display: block;
            padding: 0px 0px 99px 0px;
        }
    }

    &--bignumber {
        @media (min-width: 1024px) {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    &--footer-button {
        display: none;

        @media (min-width: 768px) {
            vertical-align: top;
            margin-right: 30px;
        }

        @media (min-width: 1024px) {
            margin-right: 203px;
        }
    }
}

.title {
    font-family: "Setimo-Bold";
    font-size: 24px;
    line-height: 26px;
    padding: 32px 0 22px;
    text-transform: uppercase;
}

.banner-time {
    &__text {
        color: #fff;
        font-family: "Setimo-Regular";
        font-size: 50px;
        line-height: 51px;
        text-transform: uppercase;
        margin-left: -5px;
        position: absolute;
        top: 100px;

        @media (min-width: 767px) {
            font-size: 120px;
            line-height: 100px;
            margin-left: -12px;
        }
    }
}

.banner {
    height: 480px;

    &__level {
        color: #fff;
        display: inline-block;
        font-family: "Georgia";
        font-style: italic;
        font-size: 26px;
        margin-bottom: 37px;

        @media (min-width: 767px) {
            font-size: 40px;
        }
    }

    &__text {
        color: #fff;
        font-family: "Setimo-Regular";
        font-size: 50px;
        line-height: 51px;
        text-transform: uppercase;
        margin-left: -5px;
        position: absolute;
        top: 100px;

        @media (min-width: 767px) {
            font-size: 120px;
            line-height: 100px;
            margin-left: -12px;
        }
    }
}

.banner-perfil {
    background: #6b123c;
    height: 963px;

    .text {
        color: #fff;
        font-family: "Setimo-Regular";
        font-size: 50px;

        @media (min-width: 767px) {
            font-size: 120px;
            line-height: 110px;
        }

        line-height: 51px;
        text-transform: uppercase;
        margin-bottom: 40px;
        margin-left: -5px;
        padding-top: 200px;
    }

    .level {
        color: #fff;
        display: inline-block;
        font-family: "Georgia";
        font-style: italic;
        font-size: 26px;
        margin-bottom: 150px;

        @media (min-width: 767px) {
            margin-left: 110px;
        }

        font-size: 40px;
        margin-left: 46px;
        margin-right: 10px;
    }

    .invertedcommas {
        color: #d5c7ba;
        display: inline-block;
        height: 50px;
        font-family: "Georgia";
        font-style: italic;
        font-size: 72px;
        padding: 0 20px;
    }

    .phrase {
        color: #fff;
        font-family: "Setimo_LtIt";
        font-size: 26px;
        line-height: 40px;
        padding: 0 20px;

        @media (min-width: 767px) {
            font-size: 50px;
            line-height: 60px;
        }
    }
}

.director {
    @media (min-width: 1024px) {
        width: 30%;
        display: inline-block;
    }

    .title {
        color: #6b123c;
        font-family: "setimo-bold";
        font-size: 42px;
        line-height: 45px;
        margin-bottom: 12px;
        padding-bottom: 0;
        padding-top: 0;
        text-transform: none;

        @media (min-width: 767px) {
            font-style: normal;
            font-weight: 700;
            margin-top: 30px;
        }
    }

    .text {
        color: #4a4a4a;
        display: inline-block;
        font-family: "Georgia";
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 25px;
    }

    .email {
        color: #f47f04;
        display: inline-block;
        font-family: "Setimo-Regular", sans-serif;
        line-height: 34px;
        margin-bottom: 4px;
        font-size: 16px;

        @media (min-width: 767px) {
            display: block;
        }

        @media (min-width: 1024px) {
            font-size: clamp(1rem, 1vw, 1.25rem);
        }
    }

    .phone {
        color: #4a4a4a;
        display: inline-block;
        font-size: 16px;
        font-family: "Setimo-Regular", sans-serif;

        @media (min-width: 767px) {
            font-size: 19px;
        }
    }

    .socials {
        display: block;
        width: 100%;
        margin-top: 20px;

        @media (max-width: 767px) {
            margin-bottom: 25px;
        }

        &__item {
            display: inline;
            margin-right: 20px;
        }
    }
}

@media (min-width: 1024px) {
    .director-text {
        width: 63%;
        margin-top: 0;
        margin-left: 6%;
    }

    .text-medium {
        margin-top: 0;
        top: 0;
    }
}

.trace {
    border-top: 3px solid #a89f88;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 767px) {
        margin-top: 52px;
    }
}

.trace-option {
    @media (min-width: 320px) {
        border-top: 2px solid #a89f88;
        margin-top: 21px;
        margin-bottom: -53px;
    }

    @media (min-width: 767px) {
        margin-top: 107px;
    }

    border-top: 2px solid #d5c7ba;
    margin-top: 36px;
    padding-top: 36px;
}

.accordion {
    overflow: hidden;

    .accordion-section-title {
        background: #6b123c;
        border-top: 1px solid #fff;
        display: flex;
        width: 100%;
        color: #f47f04;
        font-family: "Setimo-Bold-It";
        font-size: 20px;
        font-weight: 700;
        text-decoration: none;
        padding: 49px 30px;
        box-sizing: border-box;

        &::after {
            content: "\f107";
            font-family: "FontAwesome";
            margin-right: 5px;
            position: absolute;
            right: 20px;
        }

        &.active {
            background: #6b123c;
            display: flex;
            width: 100%;
            color: #fff;
            text-decoration: none;

            &::after {
                font-family: "FontAwesome";
                margin-right: 5px;
                content: "\f106";
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    .accordion-section-content {
        color: #fff;
        display: none;

        @media (max-width: 767px) {
            padding-bottom: 30px;
        }

        &.add-box {
            display: block !important;
        }
    }
}

.accordion-section:last-child .accordion-section-title {
    border-bottom: 1px solid #fff;
}

.block-text {
    @media (min-width: 767px) {
        display: inline-block;
        vertical-align: top;
    }

    .profilepage .container>& {
        display: block;
    }

    &__detail {
        background: #d5c7ba;
        display: flex;
        height: 20px;
        margin: 0 0 27px;
        width: 100%;
    }

    &__title {
        color: #6b123c;
        font-family: "setimo-bold";
        font-size: 24px;
        text-transform: uppercase;

        &--lower {
            font-size: 20px;
        }
    }

    &__text {
        color: #585858;
        font-family: "Georgia";
        font-style: italic;
    }

    &__link {
        color: #f47f04;
        text-decoration: none;
        word-wrap: break-word;
    }
}

.text-small {
    font-size: 20px;
    line-height: 31px;
    margin-top: 20px;
}

.text-medium {
    font-size: 24px;
    line-height: 36px;
    margin-top: 37px;

    @media (min-width: 768px) {
        font-size: 26px;
        position: relative;
        top: 31px;
    }

    &--home {
        white-space: pre-line;

        @media (min-width: 768px) {
            top: 0;
            margin-top: 0;
            white-space: pre-line;
        }
    }
}

.text-bigger {
    font-size: 22px;
    line-height: 31px;
    margin-top: 20px;
}

.content-about {
    display: flex;
    // display: -webkit-flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        flex-direction: row;
    }

    .btn {
        @media (min-width: 767px) {
            display: block;
            margin-top: 40px;
            width: 43%;
            font-size: 13px;
        }
    }

    .btn-enviar {
        @media (min-width: 767px) {
            width: 600px;
        }
    }

    .about-us,
    .experience {
        width: 100%;
        position: relative;
        flex: 1;
    }
}

.about-us {
    background: #6b123c;

    @media (max-width: 767px) {
        padding-bottom: 84px;
    }

    .container {
        @media (min-width: 768px) and (max-width: 1023px) {
            padding-left: 84px;
            padding-right: 84px;
        }

        @media (min-width: 768px) {
            padding-top: 70px;
            padding-bottom: 50px;
        }

        @media (min-width: 1024px) {
            padding-top: 112px;
            padding-left: 90px;
            padding-right: 90px;
        }
    }

    &__title {
        color: #d5c7ba;
        flex-direction: row;
        font-family: "Setimo-Bold";
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 24px;
        text-transform: uppercase;
    }

    &__texthighlight {
        color: #fff;
        font-family: "Setimo-Bold";
        font-size: 26px;
        line-height: 1.2;
        margin-bottom: 30px;
    }

    &__text {
        color: #fff;
        font-family: "Georgia";
        font-style: italic;
        font-size: 22px;
        line-height: 30px;

        &--detail {
            color: #f47f04;
            margin-bottom: 10px;
        }
    }
}

.portifolio {
    background: #a59682;
    margin-bottom: -50px;

    .container-accordion {
        @media (min-width: 1024px) {
            display: none;
        }
    }

    .block-text {
        @media (min-width: 1024px) {
            width: 100%;
        }

        &__detail {
            background: #d5c7ba;
            margin-top: 0;
        }

        &__title {
            color: #d5c7ba;
            text-transform: uppercase;
        }

        &__hightlight {
            color: #6b123c;
            font-family: "Setimo-Bold";
            font-size: 26px;
            margin: 24px 0;

            .page-lang-en & {
                padding-right: 32px;
            }
        }

        &__text {
            color: #fff;
            margin-top: 0;

            @media (max-width: 1023px) {
                font-size: 24px;
            }
        }

        .option {
            @extend %clearfix;

            @media (min-width: 1024px) {
                display: block;
            }
        }

        .op1,
        .op2 {
            display: block;
            float: left;
        }

        .op1 {
            @media (min-width: 1024px) {
                width: 32%;
            }
        }

        .op2 {
            @media (min-width: 1024px) {
                width: 60%;
                margin-left: 8%;
            }
        }
    }

    .accordion .accordion-section-content {
        background: #eae3dc;
    }

    .accordion .content .list {
        padding: 30px 30px 40px;
    }

    .accordion .content .list .item {
        font-size: 24px;
        line-height: 1.25;

        .item__link {
            color: #000000;
            text-decoration: none;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.commitment {
    background-color: #6b123c;
    background-image: url("../assets/img/img-engrenagem-mobile.png");
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: 767px) {
        flex-direction: row;
        background-image: url("../assets/img/img-engrenagem.png");
        background-size: cover;
        background-repeat: no-repeat;
        padding-bottom: 50px;
    }

    &__title {
        color: #d5c7ba;
        font-size: 24px;
        font-family: "Setimo-Bold";
        text-transform: uppercase;
        margin-bottom: 40px;

        @media (min-width: 767px) {
            display: none;
        }
    }

    &__hightlight {
        color: #fff;
        font-family: "Setimo-Bold";
        font-size: 36px;

        @media (min-width: 767px) {
            font-size: 60px;
        }
    }

    .text-medium {
        font-size: 24px;
        line-height: 30px;
        margin-top: 37px;

        @media (min-width: 768px) {
            font-size: 26px;
            top: 0;
        }
    }

    .block-text {
        color: #fff;

        &__text {
            color: #fff;
        }
    }
}

.expertise {
    .container {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px;

        @media (min-width: 768px) {
            padding-top: 60px;
        }
    }

    &__title {
        color: #a59682;
        font-family: "Setimo-Bold";
        font-size: 24px;
        margin-bottom: 1.33em;
        text-transform: uppercase;
    }

    &__column {
        @media (min-width: 767px) {
            float: left;
            width: 30%;
        }
    }

    &__list {
        color: #231f20;
        display: block;
        font-family: "Georgia";
        font-style: italic;
        font-size: 24px;

        @media (min-width: 1024px) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            height: 900px;
        }

        @media (min-width: 1280px) {
            max-height: 480px;
        }
    }

    &__item {
        display: block;
        font-size: 24px;

        @media (min-width: 767px) {
            border-bottom: 1px solid #cecece;
            font-size: 18px;
            padding: 20px;
        }
    }

    &-item,
    &__item,
    &__link {
        color: #000;
        font-size: 24px;
        padding-top: 0;
        padding-left: 0;
        margin-bottom: 32px;
        line-height: 1.25;
        text-decoration: none;
        outline: none;

        @media (min-width: 768px) {
            border-bottom: 1px solid #cecece;
            padding-bottom: 10px;
        }

        @media (min-width: 1024px) {
            width: calc(50% - 30px);
            padding-bottom: 10px;
            border-bottom: 1px solid #cecece;
            margin-bottom: 10px !important;
            line-height: 1.67;
        }

        @media (min-width: 1280px) {
            width: calc(33.33% - 30px);
            font-size: 19px !important;
        }
    }

    &__link {
        cursor: pointer;
        border: none;
        transition: all 0.3s linear;

        &:hover {
            color: #f47f04;
        }

        --disabled {
            cursor: default;
        }
    }
}

.accordion {
    .content {
        .list {
            .item {
                color: #231f20;
                font-family: "Georgia";
                font-style: italic;
                font-size: 24px;
                padding-bottom: 15px;
            }
        }
    }
}

.msg {
    color: #fff;
    display: flex;
    font-family: "Setimo-Bold";
    font-size: 24px;
    margin-bottom: 25px;
    padding: 20px;

    &.error {
        background: #d0021b;
    }

    &.success {
        background: #00a000;
    }
}

/*Button*/
.btn-wide {
    border-radius: 0;
    border-top: 1px solid #fff;
    font-family: "Setimo-Bold-It";
    padding: 30px 24px;
    width: 100%;

    @media (min-width: 767px) {
        display: none;
    }
}

.btn {
    background: #f47f04;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-family: "Setimo-Bold-It";
    font-size: 13px;
    padding: 15px 24px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    @media (min-width: 767px) {
        width: 50%;
        font-size: 22px;
        margin-right: 12px;
    }

    &:not(:disabled):hover {
        background: #f45304;
    }

    &.btn--about {
        text-decoration: none;
        display: block;
        font-family: "Setimo-Bold";
        font-style: italic;
        font-size: 22px;

        @media (min-width: 1024px) {
            width: 270px;
            box-sizing: border-box;
        }

        @media (max-width: 767px) {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            border-radius: 0;
            border-bottom: 1px solid #fff;
            border-top: 1px solid #fff;
            padding: 30px 24px;
            box-sizing: border-box;
        }
    }

    &.btn--contato {
        font-family: "Setimo-Regular", sans-serif;
    }
}

.read-more-state {
    display: none;
}

.read-more-target {
    font-size: 0;
    max-height: 0;
    opacity: 0;
    transition: 0.25s ease;

    @media (max-width: 767px) {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        opacity: 1;
        max-height: 9999em;
        font-size: inherit;
    }
}

.read-more-state:checked~.read-more-wrap .read-more-target {
    font-size: inherit;
    max-height: 9999em;
    opacity: 1;
    margin-bottom: 32px;
}

.read-more-state~.read-more-trigger:before {
    content: "+";
}

.read-more-state:checked~.read-more-trigger:before {
    content: "-";
}

.read-more-trigger {
    border: 1px solid #f47f04;
    border-radius: 5px;
    color: #f47f04;
    display: inline-block;
    font-family: "Georgia";
    font-style: italic;
    font-size: 38px;
    margin-top: 42px;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    width: 99%;

    @media (min-width: 768px) {
        display: none;
    }
}

.footer {
    background: #000;
    color: #fff;
    padding-top: 10px;
    width: 100%;

    .top-footer {
        padding: 49px 30px 10px 30px;

        @media (min-width: 767px) {
            margin-bottom: 41px;
            padding: 0;
        }
    }

    &__trace {
        border-bottom: 1px solid #5f5c5c;

        @media (min-width: 768px) {
            margin-bottom: 6px;
            padding-bottom: 30px;
        }
    }

    .btn-footer {
        @media (min-width: 767px) {
            display: block;
            font-size: 14px;
            width: 168px;
        }
    }

    .accordion {
        .content {
            .list {
                .item {
                    color: #fff;
                    font-size: 12px;
                    line-height: 20px;
                    margin-bottom: 15px;
                }

                .item:first-child {
                    margin-top: 29px;
                }
            }
        }

        .accordion-section:last-child {
            .accordion-section-title.active {
                border-bottom-color: transparent;
            }
        }

        .accordion-section-title {
            @media (min-width: 767px) {
                height: 25px;
                margin-bottom: 25px;
                padding: 0;

                &::after {
                    display: none;
                }
            }
        }

        .accordion-section {
            @media (min-width: 767px) {
                margin-right: 4%;
                font-size: 12px;
            }

            @media (min-width: 1024px) {
                margin-right: 8%;
                display: flex;
                flex-direction: row;
            }

            .add {
                @media (min-width: 767px) {
                    margin-left: 35px;
                    margin-top: -50px;
                }

                @media (min-width: 1023px) {
                    margin-left: 18%;
                    margin-top: 0px;
                }

                @media (min-width: 1026px) {
                    margin-left: 26%;
                    margin-top: 0px;
                }

                .address .address__link {
                    visibility: hidden;
                }
            }

            .subCategory {
                height: 25px;
                border-top: 0;
                background: transparent;
                display: block;
                text-transform: uppercase;
                font-size: 18px;
                opacity: 0.44;
                font-family: "Setimo-Bold";
                line-height: normal;
                letter-spacing: 1px;
                color: #ffffff;
                padding: 35px 30px 45px;
                margin-bottom: 4px;
                visibility: hidden;

                @media (min-width: 768px) {
                    padding: 0px;
                    margin-top: 21px;
                    margin-bottom: 4px;
                    line-height: 1.4;
                    font-size: 10px;
                    opacity: 0.5;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    .content-address {
        margin: 0 30px 0px;

        @media (min-width: 767px) {
            margin: 0;
        }
    }

    .address {
        margin-bottom: 17px;

        &__text {
            display: inline-block;
            font-family: "Setimo-Regular";
            font-size: 18px;
            line-height: 28px;
            color: #fff;
            text-decoration: none;
            margin-right: 8px;

            @media (min-width: 767px) {
                display: block;
                font-size: 12px;
                line-height: 20px;
                margin-right: 0px;
            }
        }

        &__link {
            color: #f47f04;
            display: block;
            font-family: "Setimo-Regular";
            font-size: 18px;
            line-height: 26px;

            @media (min-width: 767px) {
                display: block;
                font-size: 12px;
            }
        }
    }

    .container-footer {
        @media (min-width: 767px) {
            padding: 52px 23px;
        }
    }

    .btn-contact {
        display: inline-block;
        font-family: "Proxima_Nova_Sb";
        font-weight: 400;
        font-size: 14px;
        padding: 11px 20px;
        width: 100%;

        @media (min-width: 767px) {
            display: none;
        }
    }

    .btn-newsletter {
        display: inline;
        font-family: "Proxima_Nova_Sb";
        font-weight: 600;
        font-size: 16px;
        top: 1px;
        position: relative;
        padding: 15px 15px;
        width: 86px;
        z-index: 5;
    }

    &__logo {
        display: inline-flex;
        margin-bottom: 24px;
    }

    .socialmedia {
        @media (min-width: 767px) {
            float: right;
        }

        &__item {
            display: inline;

            img {
                height: 21px;
                margin-right: 23px;
                width: 21px;
            }
        }
    }

    .accordion {
        @media (min-width: 767px) {
            display: flex;
            justify-content: space-between;
        }
    }

    .accordion .accordion-section-title {
        background: #f47f04;
        color: #fff;
        font-family: "Setimo-Regular";
        font-size: 17px;
        letter-spacing: 2.8px;
        line-height: 1.33;
        text-transform: uppercase;

        @media (max-width: 767px) {
            &.active {
                background: transparent;
                color: #d5c7ba;

                &:first-child {
                    border-top: 0px;
                }

                &:after {
                    color: #f47f04;
                }
            }
        }

        @media (min-width: 768px) {
            background: none;
            border: none;
            color: #d5c7ba;
            display: block;
            font-family: "Setimo-Regular";
            font-size: 14px;
            letter-spacing: 2px;
            text-transform: uppercase;
            width: 157px;
        }

        @media (min-width: 1024px) {
            width: 140px;
        }
    }

    .accordion .accordion-section-content {
        @media (min-width: 767px) {
            display: flex;
            flex-direction: row;
        }
    }

    .copyright {
        color: #5f5c5c;
        display: inline-block;
        font-size: 14px;
        font-family: "Georgia";
        font-style: italic;
        line-height: 19px;
        //padding-top: 30px;
        padding-top: 12px;

        @media (min-width: 768px) {
            display: block;
            text-align: right;
        }
    }
}

.container-footer-bottom {
    display: inline-block;
    width: 100%;

    @media (min-width: 768px) {
        margin-bottom: 100px;
    }

    .container {
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.wrapper--contato {
    .container {
        padding-top: 30px;

        @media (min-width: 1024px) {
            padding-bottom: 130px;
            padding-top: 62px;
        }
    }

    .msg {
        display: none;
    }

    .contato__wrapper {
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            padding: 108px;
        }

        @media (min-width: 1024px) {
            max-width: 900px;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: flex-start;
            height: 800px;
        }

        &.privacy {
            height: auto;

            p {
                font-size: 22px;
                line-height: 31px;
                margin-top: 20px;
                color: #585858;
                font-family: "Georgia";

                a {
                    color: #f47f04;

                    &:hover {
                        color: #f45304;
                    }
                }
            }

            table {
                border: 1px solid #585858;
                border-collapse: collapse;
                margin-top: 20px;
                margin-bottom: 20px;

                th,
                td {
                    font-family: "Georgia";
                    color: #585858;
                    font-size: 18px;
                    border: 1px solid #585858;
                    border-collapse: collapse;
                    vertical-align: middle;
                    padding: 8px;
                }
            }
        }
    }

    .contato__mainblock {
        margin-bottom: 35px;
    }

    .contato__formblock {
        margin-bottom: 35px;

        @media (min-width: 1024px) {
            width: 60%;
        }

        .arquivo_de_extensao {
            width: 261px;
            font-family: "Setimo";
            font-size: 17px;
            line-height: 1.59;
            color: #949494;
            margin: 23px 0px;
        }
    }

    .contato__additionalblock,
    .contato__mainblock {
        @media (min-width: 1024px) {
            width: 40%;
            box-sizing: border-box;
            padding-right: 30px;
        }
    }

    .trabalhe-conosco__additionalblock,
    .trabalhe-conosco__mainblock {
        @media (min-width: 1024px) {
            width: 100%;
            box-sizing: border-box;
            padding-right: 30px;
        }
    }

    .contato__additionalblock {
        order: 1;
        margin-bottom: 35px;

        @media (min-width: 1024px) {
            order: 0;
        }
    }
}

.form {
    flex-direction: column;

    &__form-control {
        background: none;
        border: 1px solid #979797;
        border-radius: 4px;
        box-sizing: border-box;
        color: #979797;
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        margin-bottom: 11px;
        padding: 26px;
        display: block;
        width: 100%;
        outline: none;
    }

    .form-control-medium {
        width: 100%;
    }

    &__form-check {
        margin-top: 22px;
        margin-bottom: 11px;
        color: #585858;
        font-family: "Open Sans", sans-serif;
        font-size: 20px;

        #popup-link {
            color: #f47f04;
            cursor: pointer;
            user-select: none;

            &:hover {
                color: #f45304;
            }
        }
    }
}

.popup-layer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    .popup-content {
        width: 80vw;
        height: 80vh;
        padding: 20px;
        background-color: #f7f4f1;
        position: relative;
        z-index: 10000;

        @media (min-width: 1024px) {
            width: 50vw;
            height: auto;
        }

        .close-btn {
            width: 50px;
            height: 50px;
            position: absolute;
            right: 10px;
            top: 10px;
            text-align: center;
            cursor: pointer;

            &::after {
                content: "\00d7";
                font-size: 50px;
            }
        }

        .content {
            overflow: auto;
            height: 85%;
        }

        h2 {
            color: #231f20;
            font-family: "Setimo-Bold";
            font-size: 28px;
            line-height: 35px;
            letter-spacing: 1px;
            margin-bottom: 12px;
        }

        h4 {
            color: #231f20;
            font-family: "Setimo-Bold";
            margin-top: 24px;
            margin-bottom: 12px;
        }

        p {
            font-family: "Georgia";
            line-height: 1.38;
            color: #4a4a4a;
            margin-bottom: 12px;
        }

        li {
            font-family: "Georgia";
            line-height: 1.38;
            color: #231f20;
            margin-bottom: 12px;
        }

        ul {
            list-style: disc;
            margin-left: 24px;
        }
    }
}

.career {
    margin-bottom: 43px;

    .block-text {
        &__detail {
            margin-top: 27px;
        }
    }
}

.block-right-bottom {
    @media (min-width: 767px) {
        display: inline-block;
        position: relative;
        max-width: 275px;
    }
}

.main {
    @media (min-width: 767px) {
        max-width: 846px;
        margin: 0 auto;
    }

    @media (min-width: 1026px) {
        max-width: 1024px;
    }

    &--contained {
        @media (min-width: 768px) {
            max-width: 646px;
        }

        @media (min-width: 1024px) {
            max-width: 846px;
        }

        @media (min-width: 1280px) {
            // container padding
            width: calc(100% - 180px + 46px);
            max-width: 1024px;
        }
    }
}

@media (min-width: 767px) {
    .block-wrapper {
        display: flex;
    }

    .block-top {
        justify-content: flex-start;
        max-width: 308px;
        align-self: flex-start;
    }

    .block-form {
        width: 70%;
    }

    .block-text__detail {
        width: 213px;
        height: 32px;
    }

    .block-text__title {
        padding-right: 25px;
    }

    .form {
        margin-top: 0;

        .btn {
            margin-top: 14px;
        }
    }
}

.site-navigation {
    background: #6b123c;
    display: block;
    height: 48px;

    &>.input {
        display: none;
    }

    &>.label {
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 36px;
        padding: 0;
        position: absolute;
        right: 20px;
        width: 36px;
        z-index: 1;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &>input:checked+.main-menu {
        background: #6b123c;
        color: #fff;
        display: block;
        position: relative;
        top: 0;
        z-index: 0;
    }

    .main-menu {
        display: none;
    }

    .list {
        display: inline-block;
        margin-top: 29px;
        padding: 0;
    }

    .item {
        display: flex;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .link {
        color: #f47f04;
        font-family: "setimo-bold";
        font-size: 22px;
        padding: 8px 30px;
        text-decoration: none;
        word-wrap: break-word;

        &.active {
            color: #fff;
            border-left: 10px solid #fff;
            padding-left: 20px;
        }

        &:hover {
            color: #f45304;
        }
    }
}

.icon {
    height: 40px;
    position: relative;
}

.line {
    background: #f47f04;
    border-radius: 10px;
    height: 4px;
    position: absolute;
    transition: all cubic-bezier(0.25, 0.1, 0.28, 1.54) 0.32s;
    width: 100%;
}

.line01 {
    top: 16%;
}

.line02 {
    top: 39%;
}

.line03 {
    top: 64%;
}

.icon.close .line01 {
    top: 49%;
    transform: rotate(49deg);
}

.icon.close .line02,
.icon.close .line03 {
    top: 49%;
    transform: rotate(-49deg);
}

@media (min-width: 767px) {
    .site-navigation {
        height: auto;
    }

    .site-navigation>.label {
        display: none;
    }
}

.top-button {
    display: none;
    position: fixed;
    bottom: 60px;
    right: 60px;
    text-shadow: 2px 2px 4px #000000;
    z-index: 1000;

    @media (max-width: 767px) {
        right: 15px;
        z-index: 0;
    }

    &__link {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        text-decoration-line: none;
    }
}

.margin-80 {
    @media (min-width: 767px) {
        margin-bottom: 80px !important;
    }
}

.lightbox-newsletter {
    padding: 50px;
    background: #fff;
    position: relative;
    top: 45%;

    &__title {
        display: block;
        font-family: "Proxima_Nova_Sb";
        font-weight: 600;
        font-size: 18px;
        color: #000;
    }
}

/* The Close Button */
.close {
    color: #000;
    position: relative;
    top: 0;
    right: 0;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

.lightbox {
    /** Default lightbox to hidden */
    display: none;

    /** Position and style */
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
}

.region {
    display: block;
}

.region-title {
    font-family: Setimo-Bold;
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 2px;
    color: #f47f04;
    padding-bottom: 20px;
    text-transform: uppercase;

    @media (max-width: 767px) {
        padding-top: 30px;
        padding-left: 30px;
        font-size: 25px;
    }
}

.back-top {
    font-family: Setimo-Bold;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-right: 10px;
    text-decoration: none;
    text-shadow: 1px #d5c7ba;
}

.line-copy {
    @media (max-width: 767px) {
        margin: 30px 30px 10px 30px;
        opacity: 0.49;
        border: solid 1px #979797;
    }
}

.footer-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 30px 30px 30px;
    box-sizing: border-box;

    @media (min-width: 768px) {
        flex-direction: row;
        padding: 0px;
        justify-content: right;
    }

    .copyright2 {
        font-family: Georgia;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.57;
        letter-spacing: normal;
        color: #ffffff;

        @media (min-width: 767px) {
            display: none;
        }
    }

    .register {
        display: flex;
        flex-direction: column;
        display: none;

        p {
            font-family: "Setimo-Regular";
            font-size: 12px;
            line-height: 20px;
            color: #d5c7ba;

            @media (min-width: 768px) {
                text-align: right;
            }
        }
    }
}



.cookies {
    position: fixed;
    z-index: 1001;
    width: 100vw;
    left: 0;
    bottom: 0;
    background-color: #6b123c;
    padding: 35px 30px;
    box-sizing: border-box;

    .close {
        position: absolute;
        width: 38px;
        height: 38px;
        background-color: #f47f04;
        right: 0;
        top: -19px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #f45304;
        }

        .close-line {
            width: 22px;
            height: 2px;
            background-color: #fff;
            position: absolute;

            &:nth-of-type(1) {
                transform: rotate(49deg);
            }

            &:nth-of-type(2) {
                transform: rotate(-49deg);
            }
        }

        @media (min-width: 768px) {
            right: 16px;
        }
    }

    .cookies-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-family: "Setimo-Bold";
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            text-align: center;
            letter-spacing: 0.5px;

            @media (max-width: 768px) {
                margin-bottom: 18px;

                span {
                    display: block;
                }
            }

            @media (min-width: 768px) {
                span {
                    margin-left: 8px;
                }
            }

            a {
                color: #f47f04;
            }
        }

        .content {
            p {
                font-family: "Georgia";
                font-size: 14px;
                line-height: 22px;
                color: #fff;
                text-align: center;

                a {
                    color: #f47f04;
                }

                @media (min-width: 768px) {
                    text-align: left;
                    font-size: 12px;
                }

                &.mobile {
                    max-width: 340px;
                }
            }

            h1 {
                font-family: "Georgia";
                font-size: 16px;
                line-height: 22px;
                color: #fff;
            }

            .mobile {
                @media (min-width: 768px) {
                    display: none;
                }
            }

            .desk {
                @media (max-width: 768px) {
                    display: none;
                }
            }

            @media (max-width: 768px) {
                margin-bottom: 24px;
            }

            @media (min-width: 768px) {
                margin: 0 40px;
            }
        }

        @media (min-width: 768px) {
            flex-direction: row;

            >h1 {
                font-size: 22px;
                padding-right: 40px;
                border-right: 1px solid #fff;
                height: 55px;
                display: flex;
                align-items: center;
            }
        }
    }

    .btn {
        font-size: 14px;

        @media (min-width: 768px) {
            width: 160px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
    }
}

.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(40px);
    opacity: 0;
}



.botoes_politica_privacidade {
    display: flex;
    flex-direction: column;

    a {
        color: #f47f04;
        font-family: "Setimo-Regular";
        font-size: 15px;
    }

    @media (min-width: 768px) {
        flex-direction: row;

        a {
            line-height: 17px;

            &:nth-child(1) {
                padding-right: 12px;
                border-right: 1px solid #97979766;
                margin-right: 12px;
            }
        }
    }

    @media (max-width: 768px) {
        margin-top: 30px;

        a:nth-child(1) {
            margin-bottom: 20px;
        }
    }
}