.container--prizelist {
    @media (min-width:1024px) {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    @media (min-width:1280px) {
        max-width: 1140px !important;
        margin-left: auto;
        margin-right: auto;
    }

    .block-text__title {
        color: #a59682;
        margin-bottom: 24px;

        @media (min-width:1024px) {
            margin-bottom: 40px;
            text-align: center;
        }
    }
}

.prizelist {
    position: relative;
    height: 150px;

    &__description2 {
        cursor: initial;
        padding: 30px;
        overflow-y: auto;
        max-height: 80vh;
    }

    &__item {
        display: block;
        text-align: center;
        outline: none;

        &.noimage {
            display: flex;
            text-align: center;
            height: 130px;
            align-items: center;
        }
    }

    &__link {
        outline: none;
        text-decoration: none;
    }

    &__image {
        margin: 0 auto;
        cursor: pointer;
    }


    .swiper-button-next,
    .swiper-button-prev {
        width: 25px !important;
        height: 32px !important;
        background-size: 17px 31px !important;
    }

    .swiper-container-autoheight,
    .swiper-container-autoheight .swiper-slide {
        height: 135px !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-next {
        right: -10px !important;
        background-image: url('../assets/img/img-prizelist-arrow-next.png') !important;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-prev {
        left: -10px !important;
        background-image: url('../assets/img/img-prizelist-arrow-prev.png') !important;
    }
}


.prizelist__description {
    color: #4a4a4a;
    background-color: #f2f2f2;
    letter-spacing: 0.2px;
    padding: 30px;
    width: 90vw;

    @media (min-width:600px) {
        width: 50vw;
    }
}

.prizelist__description_a2 {
    color: #4a4a4a;
    background-color: #f2f2f2;
    letter-spacing: 0.2px;
    padding: 30px;
    overflow: auto;
    width: 100%;
    cursor: text;
}



.prizelist__bg-modal {
    position: fixed;
    background: RGBA(30, 30, 30, 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
}

.prizelist__modal {
    color: #4a4a4a;
    background-color: #f2f2f2;
    letter-spacing: 0.2px;
    padding: 0;
    position: relative;
    top: 50%;
    margin: 0 auto;
    width: 86vw;
    transform: translateY(-50%);

    @media (max-width:768px) {
        height: 600px;
    }

    @media (min-width:768px) {
        width: 80%;
    }

    @media (min-width:1024px) {

        width: 60%;
    }

    @media (min-width:1366px) {
        width: 750px;
    }


    .icoX {
        font-family: 'Open Sans', sans-serif;
        cursor: pointer;
        font-size: 22px;
        position: absolute;
        top: 35px;
        right: 9px;
        z-index: 2;

        @media (min-width:1024px) {
            right: 30px;
        }
    }

    .swiper-slide {
        @media (max-width:1024px) {
            height: 600px;
            overflow: auto;
        }
    }
}



.swiper-button-next,
.swiper-container-rtl .swiper-button-next {
    right: 0px;

    @media (min-width:1024px) {
        right: 10px;
    }

    background-image: url('../assets/img/img-prizelist-arrow-next.png') !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-prev {
    left: 0px;

    @media (min-width:1024px) {
        left: 10px;
    }

    background-image: url('../assets/img/img-prizelist-arrow-prev.png') !important;
}




.prizelist__title {
    font-weight: 700;
    line-height: 25px;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.prizelist__title-noimage {
    color: #6b123c;
    font-family: 'Setimo-Bold';
    line-height: 25px;
    font-size: 26px;
}

.swiper-no-swiping {
    top: 0;
    position: relative;
    line-height: 25px;
    font-size: 18px;
    font-style: italic;
    word-break: break-word;

    a {
        color: #6b123c;
        text-decoration: none;
        cursor: pointer;
    }
}

.prizelist__content {

    a,
    u,
    p,
    span,
    div {
        font-family: 'Times New Roman', Times, serif !important;
        line-height: 25px !important;
        font-size: medium !important;
        font-style: italic !important;
        word-break: break-word !important;
        color: #4a4a4a !important;
    }

    strong {
        font-weight: 700 !important;
    }

    a,
    u {
        color: #6b123c !important;
        text-decoration: none !important;
        cursor: pointer !important;
    }
}