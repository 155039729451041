@font-face {
    font-family: 'Setimo_LtIt';
    src: url('../assets/fonts/setimo/Setimo_It.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Setimo-Bold';
    src: url('Setimo-Bold.eot');
    src: url('../assets/fonts/setimo/setimo-bold/Setimo-Bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/setimo/setimo-bold/Setimo-Bold.woff2') format('woff2'), url('../assets/fonts/setimo/setimo-bold/Setimo-Bold.woff') format('woff'), url('../assets/fonts/setimo/setimo-bold/Setimo-Bold.ttf') format('truetype'), url('../assets/fonts/setimo/setimo-bold/Setimo-Bold.svg#Setimo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Setimo-Regular';
    src: url('../assets/fonts/setimo/setimo-regular/Setimo-Regular.eot');
    src: url('../assets/fonts/setimo/setimo-regular/Setimo-Regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/setimo/setimo-regular/Setimo-Regular.woff2') format('woff2'), url('../assets/fonts/setimo/setimo-regular/Setimo-Regular.woff') format('woff'), url('../assets/fonts/setimo/setimo-regular/Setimo-Regular.ttf') format('truetype'), url('../assets/fonts/setimo/setimo-regular/Setimo-Regular.svg#Setimo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Setimo-Bold-It';
    src: url('../assets/fonts/setimo/setimo-bold-it/Setimo-BoldItalic.eot');
    src: url('../assets/fonts/setimo/setimo-bold-it/Setimo-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/setimo/setimo-bold-it/Setimo-BoldItalic.woff2') format('woff2'), url('../assets/fonts/setimo/setimo-bold-it/Setimo-BoldItalic.woff') format('woff'), url('../assets/fonts/setimo/setimo-bold-it/Setimo-BoldItalic.ttf') format('truetype'), url('../assets/fonts/setimo/setimo-bold-it/Setimo-BoldItalic.svg#Setimo-BoldItalic') format('svg');
    font-weight: bold;
}

@font-face {
    font-family: 'Proxima_Nova_Sb';
    src: url('../assets/fonts/proxima-nova-semibold/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
     url('../assets/fonts/proxima-nova-semibold/ProximaNova-Semibold.woff2') format('woff2'),
      url('../assets/fonts/proxima-nova-semibold/ProximaNova-Semibold.woff') format('woff'),
      url('../assets/fonts/proxima-nova-semibold/ProximaNova-Semibold.ttf') format('truetype'),
      url('../assets/fonts/proxima-nova-semibold/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Georgia';
    src: url('../assets/fonts/georgia/Georgia.eot');
    src: url('../assets/fonts/georgia/Georgia.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/georgia/Georgia.woff2') format('woff2'), url('../assets/fonts/georgia/Georgia.woff') format('woff'), url('../assets/fonts/georgia/Georgia.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Georgia Bold Italic';
    src: url('../assets/fonts/georgiaBoldItalic/Georgia-BoldItalic.eot');
    src: url('../assets/fonts/georgiaBoldItalic/Georgia-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/georgiaBoldItalic/Georgia-BoldItalic.woff2') format('woff2'), url('../assets/fonts/georgiaBoldItalic/Georgia-BoldItalic.woff') format('woff'), url('../assets/fonts/georgiaBoldItalic/Georgia-BoldItalic.ttf') format('truetype');
}
