#mvv {
    margin-top: -43px;
    padding: 52px 0px 52px 0px;
    width: 100%;
    overflow: hidden;

    @media (min-width: 768px) {
        padding: 70px 0px 70px 0px;
    }

    .mvv-container {
        @media (min-width: 768px) {
            max-width: 646px;
        }

        @media (min-width: 1024px) {
            max-width: 846px;
        }

        @media (min-width: 1280px) {
            max-width: 1024px;
        }
    }

    .mobile {
        @media (min-width: 768px) {
            display: none !important;
        }
    }

    .desk {
        @media (max-width: 768px) {
            display: none !important;
        }
    }

    .item-selector {
        display: flex;
        align-items: center;
        margin: 0px auto 40px auto;

        @media (min-width: 768px) {
            .item-selector-option {
                flex: 1;
                position: relative;

                &:not(:last-child) {
                    &:after {
                        content: '';
                        position: absolute;
                        right: 40px;
                        top: 0;
                        width: 1px;
                        height: 70px;
                        background-color: #d5c7ba;

                    }
                }
            }
        }

    }

    .swiper-container {
        margin-bottom: 30px;
    }

    .title-container {
        position: relative;
        margin-bottom: 30px;
        white-space: nowrap;
        width: 220px;

        @media (min-width: 768px) {
            margin-bottom: 0px;
        }

        h3 {
            color: #a59682;
            font-family: "Setimo-Regular";
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            text-transform: uppercase;
            position: absolute;
            left: 0;
            top: 22px;
            z-index: 2;


            @media (min-width: 768px) {
                cursor: pointer;
            }
        }

        .index {
            position: relative;
            z-index: 1;
            font-family: "Setimo-Regular";
            font-size: 80px;
            color: #d5c7ba;
            opacity: .3;

            // @media (min-width: 768px) {
            //     font-size: 70px;
            // }
        }

        &.active {
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 80px;
                height: 4px;
                background-color: #f47f04;
            }

        }
    }

    .slide {
        padding: 0px 30px;
        margin: 0px auto;
        min-height: 520px;

        @media (min-width: 768px) {
            min-height: 260px;
        }


        .mvv-content {
            font-family: "Setimo-Regular", sans-serif;
            line-height: 1.5;
            color: #6b123c;
            font-size: 18px;

            strong {
                font-family: "Setimo-Bold", sans-serif;
            }

            @media (min-width: 768px) {
                font-size: 22px;
                min-height: 250px;
            }
        }
    }

    #controll-btns {
        display: flex;
        align-items: center;
        margin: 0px auto;
        padding: 0px 30px;


        .swiper-button-disabled {
            opacity: .5;
        }

        #swiper-btn-prev {
            margin-right: 20px;
        }

        #swiper-btn-next,
        #swiper-btn-prev {
            width: 25px;
            height: 18px;
            background-size: 17px 31px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
        }

        #swiper-btn-next,
        #swiper-container-rtl #swiper-btn-next {
            background-image: url('../assets/img/img-prizelist-arrow-next.png');
        }

        #swiper-btn-prev,
        #swiper-container-rtl #swiper-btn-prev {
            background-image: url('../assets/img/img-prizelist-arrow-prev.png');
        }

        p {
            font-family: "Georgia";
            line-height: 1.38;
            color: #a59682;
            font-size: 16px;
            margin-left: 20px;

            @media (min-width: 768px) {
                display: none;
            }
        }
    }





}