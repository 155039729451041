// base
.experience {
    &__text {
        color: #231f20;
        font-family: 'Setimo-Bold';
        font-size: 28px;
        line-height: 35px;
        letter-spacing: 1px;
        margin: 0;
    }

    &__blockwrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @media (min-width:768px) {
            display: block;
        }
    }
}

.experienceblock {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__text {
        color: #231f20;
        font-family: 'Georgia';
        font-style: italic;
        font-size: 18px;
        word-wrap: break-word;
        width: 57%;
    }

    &__hightlight {
        color: #f47f04;
        font-family: 'Setimo-Bold';
        text-align: right;
        width: 130px;
        font-size: 50px;


        @media (min-width:767px) {
            font-size: 60px;
        }
    }
}

// personalizacoes de bloco
.experience {
    .container--homeexperience {
        @media (max-width:1023px) {
            background-image: url(../assets/img/img-bg-rede-mobile.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            padding: 60px 40px;
        }

        @media (min-width:768px) and (max-width:1023px) {
            padding-left: 84px;
            padding-right: 84px;
        }

        @media (min-width:1024px) {
            padding-bottom: 0;
            padding-top: 60px;
        }
    }
}

// personalizacoes de elemento por pagina
.experience {

    // home page
    &--home {
        .experience__content {
            background: rgba(213, 199, 186, .5);
        }

        .experience__blockwrapper {
            margin-left: auto;
            margin-right: auto;
            max-width: 260px;

            @media (min-width:768px) {
                max-width: 360px;
            }

            @media (min-width:1024px) {
                max-width: 410px;
                padding-bottom: 60px;
            }
        }

        .experienceblock {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .experienceblock__hightlight,
        .experienceblock__text {
            display: block;
        }

        .experienceblock__hightlight {
            text-align: right;

            @media (min-width:150px) {
                width: 150px;
                font-size: 60px;
            }
        }

        .experienceblock__text {
            text-align: left;
            padding-left: 13px;
            font-size: 20px;
            box-sizing: border-box;

        }
    }

    // historia page
    &--historia {
        .container--experience-title {
            padding-bottom: 0;
        }

        .experience__blockwrapper {
            -webkit-flex-wrap: wrap !important;

            @media (max-width:767px) {
                margin-left: auto;
                margin-right: auto;
                max-width: 260px;
            }

            @media (min-width:768px) {
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                flex-direction: row;
                -ms-flex-wrap: wrap;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                width: 100%;
                max-width: none;
            }

            @media (min-width:1024px) {
                padding-bottom: 0;
            }
        }

        .experienceblock {
            margin-bottom: 40px;

            @media (min-width:768px) {
                width: 33.33%;
                display: block;
                padding-left: 0px;
                padding-right: 20px;
                box-sizing: border-box;
                margin-bottom: 25px;
                margin-left: 0;
                margin-right: 0;
            }

            @media (min-width:1024px) {
                width: 25%;
                margin-bottom: 52px;
                padding-right: 40px;
            }

            .experienceblock__hightlight,
            .experienceblock__text {
                &--nowrap {
                    // white-space: nowrap;
                }

                display: block;

                @media (min-width:768px) {
                    width: 100%;
                }
            }

            .experienceblock__hightlight {
                text-align: right;

                &--smaller {
                    @media (max-width:767px) {
                        font-size: 42px;
                    }
                }

                @media (min-width:768px) {
                    text-align: left;
                    width: 100%;
                    line-height: 1;
                }
            }

            .experienceblock__text {
                text-align: left;
                float: none;
                padding-left: 13px;
                font-size: 19px;

                @media (min-width:768px) {
                    text-align: left;
                    padding-left: 0;
                    font-size: 24px;
                    letter-spacing: 1px;
                    line-height: 1;
                }

                @media (min-width:1024px) {
                    font-size: 28px;
                }
            }
        }
    }
}