@media screen and (max-width: 767px) {
    .mainbanner__title {
        font-size: 43px;
        line-height: 0.98;
        font-weight: normal;
    }

    .mainbanner__image {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
    }

    .container {}

    .block-text {
        width: 100%;
        margin-bottom: 22px;
        text-align: center;

        &__title {
            width: 100%;
            font-size: 20px;
        }

        &__text {
            width: 100%;
            font-size: 20px;
        }

    }

    .arquivo_de_extensao {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        p {
            font-family: 'Setimo-regular';
        }
    }

    .filename {
        display: none;
        margin: 0px 0px 20px !important;

        p {
            font-family: 'Setimo-regular';
        }
    }

    .btn-anex {
        margin: auto;
        background: #fff;
        border: 1px solid #f47f04;
        border-radius: 4px;
        color: #f47f04;
        cursor: pointer;
        font-family: 'Setimo-Bold-It';
        font-size: 22px;
        padding: 15px 24px;
        text-align: center;
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 62px;
        text-decoration: none;
    }

    .mainbanner__title--contato {
        font-weight: normal;
    }

    .botoes_entrarEmContato_e_trabalheConosco {
        margin-top: 80px;
        margin-bottom: 28px;
    }

    .btn_eec {
        margin-bottom: 28px;
    }

    .botoes_entrarEmContato_e_trabalheConosco_2 {}

    .btn_eec2 {
        float: left;
        margin-bottom: 13px;

        @media (min-width:768px) {
            float: right;
        }
    }

    .files_anex_btn {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .arquivos_e_texto {
        font-size: 19px;
        color: #949494;
        line-height: 1.58;
        margin-bottom: 30px;

        p {
            font-family: 'Setimo-regular';
            line-height: 1.58;
            font-size: 19px;
        }
    }

    .botao_anexar {
        width: 100%;
    }

}


@media (min-width:768px) {
    .mainbanner__title {
        font-size: 90px;
        line-height: 1.33;
        font-weight: normal;
    }

    .mainbanner__image {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
    }

    .container {
        margin-top: 42px;
    }

    .block-text {
        width: 100%;
        margin-bottom: 22px;
        text-align: left;

        &__title {
            width: 100%;
            font-size: 20px;
        }

        &__text {
            width: 100%;
            font-size: 20px;
        }

    }

    .arquivo_de_extensao {
        float: left;
        margin-top: 62px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        font-size: 19px;

        p {
            font-family: 'Setimo-regular';
        }

    }

    .filename {
        display: none;
        margin: 0px 0px 20px !important;
    }

    .btn-anex {

        margin: 15px auto;
        background: #fff;
        border: 1px solid #f47f04;
        border-radius: 4px;
        color: #f47f04;
        cursor: pointer;
        font-family: 'Setimo-Bold-It';
        font-size: 22px;
        padding: 15px 24px;
        text-align: center;
        width: 245px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-decoration: none;
    }

    .botoes_entrarEmContato_e_trabalheConosco {
        display: none;
        margin-top: 80px;
        margin-bottom: 28px;
        width: 100%;
    }



    .btn_eec {
        margin-bottom: 28px;
    }

    .botoes_entrarEmContato_e_trabalheConosco_2 {
        display: flex;

    }

    .btn-eec2 {
        margin-bottom: 28px;
    }

    .files_anex_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .arquivos_e_texto {
        font-family: 'Setimo-regular';
        font-size: 19px;
        color: #949494;
        line-height: 1.58;

        p {
            font-family: 'Setimo-regular';
            line-height: 1.58;
            font-size: 19px;
        }
    }

}

@media (min-width:1025px) {
    .mainbanner__title {
        font-size: 90px;
        line-height: 1.33;
        font-weight: normal;

    }

    .mainbanner__image {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
    }

    .container {
        margin-top: 42px;
    }

    .block-text {
        width: 100%;
        margin-bottom: 22px;
        text-align: left;

        &__title {
            width: 100%;
            font-size: 20px;
        }

        &__text {
            width: 100%;
            font-size: 20px;
        }

    }

    .botoes_entrarEmContato_e_trabalheConosco {
        margin-top: 80px;
        margin-bottom: 28px;
    }

    .btn_eec {
        margin-bottom: 28px;
    }

    .arquivos_button {
        display: block;
        width: 100%;


        .arquivo_de_extensao {
            float: left;
            margin-top: 62px;
            width: 20%;
            font-size: 19px;

            p {
                font-family: 'Setimo-regular';
                font-size: 20px;
                line-height: 27px;
            }
        }

        .btn-anex {
            margin: 15px auto;
            background: #fff;
            border: 1px solid #f47f04;
            border-radius: 4px;
            color: #f47f04;
            cursor: pointer;
            height: 62px;
            font-family: 'Setimo-Bold-It';
            font-size: 22px;
            padding: 15px 24px;
            text-align: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-decoration: none;
        }
    }

    .arquivos_e_texto {
        font-family: 'Setimo-regular';
        font-size: 19px;
        color: #949494;
        line-height: 1.58;

        p {
            font-family: 'Setimo-regular';
            line-height: 1.58;
            font-size: 19px;
        }
    }

}

.btn-enviar {
    background: #f47f04;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-family: 'Setimo-Bold-It';
    font-size: 22px;
    padding: 15px 24px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;

    @media (min-width: 767px) {
        width: 50%;
        font-size: 22px;
        margin-right: 12px;


    }

    &:not(:disabled):hover {
        background: #f45304;
    }

    &.btn--about {
        text-decoration: none;
        display: block;
        font-family: 'Setimo-Bold';
        font-style: italic;
        font-size: 22px;

        @media (min-width:1024px) {
            max-width: 271px;
            box-sizing: border-box;
        }

        @media (max-width:767px) {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            border-radius: 0;
            border-bottom: 1px solid #fff;
            border-top: 1px solid #fff;
            padding: 30px 24px;
            box-sizing: border-box;
        }
    }

    &.btn--contato {
        font-family: 'Setimo-Regular', sans-serif;
    }
}