.container--profileteam {
	padding-top: 40px !important;
	@media (min-width:1024px) {
		padding-bottom: 44px !important;
	}
	@media (min-width:1280px) {
		max-width: 1140px !important;
		margin-left: auto;
		margin-right: auto;
	}

	.block-text-team {
		&__title {
			color: #666666;
			font-family: 'Setimo-Bold';
			line-height: 45px;
			letter-spacing: 0.45px;
			font-size: 22px;
			padding-bottom: 20px;
			@media (min-width:1024px) {
				font-size: 23px;
				letter-spacing: 0.52px;
				padding-bottom: 23px;
			}
		}
	}

	.prizelist {
		position: relative;
		height: 100px;
		margin-bottom: 44px;
		@media (min-width:1024px) {
			margin-bottom: 0px;
		}

		&__item {
			display: block;
			text-align: center;
			outline: none;
		  padding-top: 5px;

			&.noimage {
				display: flex;
				text-align: center;
				height: 130px;
				align-items: center;
			}
		}

		&__link {
			outline: none;
			text-decoration: none;
		}

		&__image {
			margin: 0 auto;
			width: 80px;
		}

		.swiper-button-next,
		.swiper-button-prev {
			width: 25px !important;
			height: 32px !important;
			background-size: 17px 31px !important;
		}

		.swiper-container-autoheight,
		.swiper-container-autoheight .swiper-slide {
			height: 135px !important;
		}

		.swiper-button-next,
		.swiper-container-rtl .swiper-button-next {
			right: -20px !important;
			background-image: url('../assets/img/img-prizelist-arrow-next.png')!important;
		}

		.swiper-button-prev,
		.swiper-container-rtl .swiper-button-prev {
			left: -20px !important;
			background-image: url('../assets/img/img-prizelist-arrow-prev.png')!important;
		}
	}

	.prizelist__description {
		color: #4a4a4a;
		background-color: #f2f2f2;
		letter-spacing: 0.2px;
		padding: 30px;
		width: 90vw;
		@media (min-width:600px) {
			width: 50vw;
		}
	}

	.prizelist__title {
		font-weight: 700;
		line-height: 25px;
		font-size: 26px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	.prizelist__title-noimage {
		color: #6b123c;
		font-family: 'Setimo-Bold';
		line-height: 25px;
		font-size: 26px;
	}

	.prizelist__content {
		line-height: 25px;
		font-size: 18px;
		font-style: italic;
	}
}
