.operationtabs {
	display: none;
	@media (min-width:1024px){
		display: block;
	}
	&__menu {
		display: -webkit-flex;
		display: flex;
		flex-flow: row nowrap;
		width: 100%;
	}
	&__button {
		flex: 1 1 auto;
		font-family: 'Setimo-Bold-It';
		font-size: 14px;
		line-height: .7;
		color: #fff;
		display: block;
		margin: 0 2px 0 0;
		border: none;
		box-shadow: none;
		outline: none;
		width: 164px;
		cursor: pointer;
		background-color: #f47f04;
		border-radius: 4px;
		padding: 17px 20px;
		@media (max-width:1024px){
		padding: 17px 25px;
		}
		line-height: 18px;
		transition: .2s ease;
		.operationtabs.has-started & {
			border-radius: 4px 4px 0 0;
		}
		&:hover:not(.active) {
			background-color: lighten(#f47f04, 5%);
			// color: #6b123c;
		}
		&.active {
			background-color: #d5c7ba;
			color: #6b123c;
		}
		@media (min-width:1024px){
			font-size: 16px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&__contentblock {
		display: none;
		background-color: #d5c7ba;
		padding: 30px 50px;
		border-radius: 0 0 4px 4px;
		height:138px;
		@media (min-width:1024px){
			height:auto;
		}
		flex-flow: row nowrap;
		-webkit-flex-flow: row nowrap;
		justify-content: flex-start;
		.operationtabs.has-started & {
			display: -webkit-flex;
			display: -webkit-box-flex;
			display: flex;
		}
		@media (min-width:1024px){
			flex-wrap: wrap;
		}
		@media (min-width:1280px){
			flex-wrap: nowrap;
		}
	}
	&__contentlist {
		width: 50%;
		box-sizing: border-box;
		padding-right: 12px;
		display: inline-block;
		@media (min-width:1280px){
			width: auto;
			flex: 1 1 auto;
		}
	}
	&__contentitem, &__link{
		font-family: 'Georgia';
		font-size: 18px;
		font-style: italic;
		line-height: 1.3;
		text-decoration: none;
		outline: none;
		color: #6b123c;
		padding-bottom: 8px;
		max-width: 360px;
		@media (min-width:1024px){
			font-size: 19px;
		}
	}

	&__link{
		transition: all 0.3s linear;

		&:hover{ color: #f47f04; }
	}
}
