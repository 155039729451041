.historiablock {
    margin-bottom: 40px;

    &__title {
        @media (min-width:1024px) {
            width: 30%;

            .ie & {
                width: 100%;
            }
        }
    }

    &__content {
        @media (min-width:1024px) {
            width: 70%;

            .ie & {
                width: 100%;

                .block-text__text {
                    top: 0;
                }
            }
        }
    }

    @media (min-width:1024px) {
        display: flex;
        flex-direction: row;
        margin-bottom: 85px;

        .ie & {
            display: block;
            margin-bottom: 20px;
        }

        .block-text {
            &__title {
                margin-bottom: 30px;
                padding-right: 70px;
            }

            display: inline-block;
            margin-top: 40px;
        }
    }

    .block-text__text {
        margin-bottom: 20px;
        margin-top: 0;
        line-height: 34px;

    }

    .block-text__title {
        margin-bottom: 20px;
        line-height: 1.25;

        @media (min-width:768px) {
            font-size: 32px;
        }
    }

    .block-text__detail {
        height: 26px;
    }
}

.historiabanner {
    max-width: 1024px - 46px;
    margin-left: auto;
    margin-right: auto;

    .container {
        @media (min-width:768px) {
            padding: 0 !important;
        }
    }

    @media (min-width:768px) {
        margin-bottom: 20px;
    }

    &__item {
        position: relative;

        img {
            width: 100%;
            display: block;
        }
    }

    &__media {
        display: block;
        margin-bottom: 25px;

        @media (min-width:768px) {
            margin: 0;
        }
    }

    &__content {
        @media (min-width:768px) {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            padding-left: 50px;
            padding-right: 20px;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 75%;
        }

        @media (min-width:1024px) {
            width: 65%;
            padding-left: 90px;
            padding-right: 90px;
        }
    }

    &__title {
        color: #6b123c;
        font-family: 'setimo-bold';
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 20px;
        text-transform: uppercase;

        @media (min-width:768px) {
            font-size: 32px;
        }
    }

    &__text {
        color: #585858;
        font-family: 'Georgia';
        font-style: italic;
        font-size: 24px;
        line-height: 34px;
    }

    &__caption {
        text-align: center;
        color: #585858;
        font-size: 18px;
        font-family: 'Georgia';
        font-style: italic;
        margin-top: 16px;
        padding: 0 12px;
    }
}

.container--experience-title {
    .block-text {
        .block-text__title {
            font-size: 32px;
        }
    }
}

.history-wrapper {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        margin-top: 0px !important;
    }

    .history-block {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;

        @media (max-width: 768px) {
            &:last-of-type {
                margin-bottom: 0px;
            }
        }

        @media (min-width: 768px) {
            flex-direction: column;
            margin-bottom: 0px;
        }

        .history-hightlight {
            color: #f47f04;
            font-family: 'Setimo-Bold';
            text-align: right;
            width: 50%;
            flex-shrink: 0;
            font-size: 50px;



            @media (min-width: 768px) {
                width: 100%;
                text-align: left;
                font-size: 60px;
            }
        }

        .history-text {
            color: #231f20;
            font-family: 'Georgia';
            font-style: italic;
            font-size: 19px;
            word-wrap: break-word;
            width: 50%;
            padding-left: 13px;

            @media (min-width: 768px) {
                width: 100%;
                padding-left: 0px;
                font-size: 28px;
            }
        }
    }


}