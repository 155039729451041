.mainbanner {
    display: block;

    picture {
        display: block;
    }

    &--static {
        margin-bottom: 20px;

        @media (min-width: 768px) {
            margin-bottom: 60px;
        }
    }

    &:not(.slick-slider) {
        .mainbanner__item {
            &:nth-child(n+2) {
                display: none;
            }
        }
    }

    &__item {
        position: relative;

        &.noimage {
            background: #a59682;
            background-image: linear-gradient(rgba(20, 23, 32, 0.5), #a59682);
            height: 482px;

            @media (min-width: 768px) {
                height: 772px;
            }
        }
    }

    &__title {
        position: absolute;
        text-transform: uppercase;
        font-family: Setimo-Regular;
        font-size: 50px;
        line-height: 1;
        font-weight: normal;
        color: #fff;
        padding-right: 20px;
        left: 0;
        top: 52%;
        transform: translateY(-50%);

        a {
            outline: none;
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &--static {
            @media (max-width: 767px) {
                font-size: 50px;
            }
        }

        &--contato {
            font-weight: bold;

            @media (max-width: 767px) {
                font-size: 60px;
            }
        }

        &--static {
            @media (max-width: 767px) {
                font-size: 50px;
            }
        }

        @media (min-width:360px) {
            font-size: 40px;
        }

        @media (min-width:768px) {
            top: 55%;
            width: 90%;
            font-size: 80px;
        }

        @media (min-width:1024px) {
            font-size: 70px;
        }

        @media (min-width:1200px) {
            font-size: 80px;
        }

        @media (min-width:1600px) {
            font-size: 100px;
        }

        @media (min-width:1900px) {
            width: 1100px;
        }

        @media (min-width:3000px) {
            width: 58%;
        }
    }

    &__title-profile {
        position: absolute;
        text-transform: uppercase;
        font-family: Setimo-Regular;
        font-size: 49px;
        line-height: 1;
        font-weight: normal;
        color: #fff;
        left: 0;
        bottom: 2%;

        @media (max-width:768px) {
            text-shadow: black 0.1em 0.1em 0.8em;
        }

        @media (min-width:360px) {
            font-size: 40px;
        }

        @media (min-width:768px) {
            top: 55%;
            width: 90%;
            font-size: 80px;
        }

        @media (min-width:1024px) {
            width: 73%;
            font-size: 70px;
        }

        @media (min-width:1200px) {
            font-size: 80px;
        }

        @media (min-width:1600px) {
            font-size: 100px;
        }

        .title-profile {
            position: relative;
        }
    }

    &__profilesubtitle {
        position: absolute;
        text-transform: none;
        font-family: Georgia, serif;
        font-size: 26px;
        font-style: italic;
        color: #fff;
        box-sizing: border-box;
        line-height: 30px;
        display: none;
        padding-top: 0.5em;
        left: 0;

        @media (max-width:768px) {
            text-shadow: black 0.1em 0.1em 0.6em;
        }

        @media (min-width:768px) {
            display: block;
        }

        @media (min-width:1024px) {
            font-size: 30px;
            width: 65%;
            line-height: 35px;
        }
    }

    &__media {
        display: block;
    }

    &__image {
        width: 100vw;
        max-width: 100%;
        height: 90vh;
        object-fit: cover;
        display: block;

        @media (min-width: 768px) {
            height: 100vh;
        }

        .ie & {
            width: 100%;
            height: auto;
        }
    }

    &__image-profile {
        width: 100vw;
        max-width: 100%;
        height: 482px;
        object-fit: cover;
        background-size: cover;
        display: block;
        object-position: left;

        @media (min-width: 768px) {
            height: 772px;
            object-position: right;
        }
    }
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 75px !important;
}

.swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
    background: #ec5400 !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background: #fff !important;
}

.arquivos_anexar {
    width: 546px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;


}