.chainheader {
	position: absolute;
	z-index: 100;
	width: 100%;
	padding-left: 20px;
	padding-top: 28px;
	box-sizing: border-box;
	top: 0;
	left: 0;
	@media (min-width:1024px){
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 64px;
	}
	@media (min-width:1200px){
		padding-left: 90px;
		padding-right: 90px;
	}
	.site-navigation {
		background-color: transparent !important;
	}
	.site-navigation .main-menu .link--quemsomos {
		@media (min-width:1024px){
			.page-noticias & {
				max-width: 60px;
			}
		}
	}
	.site-navigation .link br {
		@media (max-width:1023px){
			display: none;
		}
	}
	.site-navigation .list {
		@media (max-width:1023px){
			padding: 29px 0 0;
			height: calc(100vh - 59px);
			overflow-y: scroll;
			width: 100%;
			box-sizing: border-box;
			margin-top: 0;
		}
		@media (min-width:1024px){
			margin-top: 0;
		}
	}
	.site-navigation > .label {
		display: inline-block;
		right: 20px;
		z-index: 10000;
		&.close-button {
			position: fixed;
		}
		@media (min-width:1024px){
			display: none;
		}
	}
	.site-navigation .main-menu {
		.item {
			flex-direction: column;
		}
		@media (min-width:1024px){
			position: relative;
			display: block;
			float: right;
			.item {
				display: inline-flex;
				position: 	relative;
				&:hover {
					.item-submenu {
						display: block;
					}
				}
				&:nth-child(3){					
					max-width: 119px;			
				}
			}
			.link {
				font-size: 16px;
				font-family: Setimo-Regular;
				display: block;
				max-width: 105px;						
				height: 40px;
				padding: 8px 15px;		

				&.active {
					border-left: none;
				}
			}
		}
	}
	.site-navigation .item-submenu {
		position: absolute;
		min-width: 100%;
		left: 0;
		top: 100%;
		padding-top: 0;
		display: none;
		.ie & {
			z-index: 1000;
		}
		@media (max-width:1023px){
			display: block;
			position: static;
			padding: 0;
		}
	}
	.site-navigation .item-submenu .sublink {
		font-family: Setimo-Regular;
		font-size: 26px;
		display: block;
		text-decoration: none;
		padding: 15px;
		line-height: .75;
		background-color: #f47f04;
		color: 	#fff;
		border-radius: 3px;
		margin-bottom: 4px;
		@media (min-width:1024px){
			font-size: 16px;
			width: 140px;
			&:hover {
				color: #f47f04;
				background-color: #fff;
			}
			.ie & {
				margin-bottom: 0;
			}
		}
		@media (max-width:1023px){
			padding: 15px 30px;
			background: transparent;
			box-sizing: border-box;
			color: #f47f04;
			font-size: 16px;
		}
	}
	.site-navigation > input:checked + .main-menu {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: fixed;
		z-index: 9999;
		background-color: #6b123c;
	}
	&__logo {
		display: inline-block;
		height: 38px;

		img {
			height: 100%;
		}

		@media (min-width: 1024px) {
			height: 45px; 
		}
	}
	&__languageblock {
		box-sizing: border-box;
		padding: 20px 40px;
		@media (min-width:1024px){
			padding: 0 18px 0 0;
			text-align: right;
			position: absolute;
			right: 0;
			bottom: calc(100% + 16px);
		}
	}
	&__languagebutton {
		width: 26px;
		height: 19px;
		display: inline-block;
		margin-right: 6px;
		cursor: pointer;
		&:last-child {
			margin-right: 0;
		}
		&--pt {
			background-image: url('../assets/img/img-chainheader-lang-pt.svg');
			.page-lang-pt & {
				background-image: url('../assets/img/img-chainheader-lang-pt-selected.png');
			}
		}
		&--en {
			background-image: url('../assets/img/img-chainheader-lang-en.png');
			.page-lang-en & {
				background-image: url('../assets/img/img-chainheader-lang-en-selected.svg');
			}
		}
	}
}