.profilepage {
	&__wrapper {
		background-color: #6b123c;
		padding-bottom: 64px;
	}

	&__invertedcommas{
		color: #c7b8ac;
		font-family: 'Setimo-Bold';
		font-style:italic;
		font-size:72px;
		line-height: 26px;
		margin-bottom:10px;
		@media (min-width:768px){
		font-size:120px;
		margin-bottom:20px;
		margin-left: -20px;

		}

	}

	&__description {
		padding: 0 30px;
		padding-top:61px;
		box-sizing: border-box;



		@media (min-width:768px){
			padding-bottom: 33px;
			padding-top: 112px;
			margin-left: auto;
			margin-right: auto;
		}
		@media (min-width:1024px){
			max-width: 810px;
		}
		@media (min-width:1280px){
			max-width: 1200px;
		}
	}
	&__description-text {
		color: #fff;
		font-family: 'Setimo_LtIt';
		font-size: 26px;
		line-height: 40px;
		@media (min-width:768px){
			font-size: 24px;
			line-height:35px;
		}
	}
	.block-text__text.text-medium {
		font-size: 16px;
		letter-spacing: .03px;
		font-style: normal;
		line-height: 2;
		margin-top: 0;
		margin-bottom: 30px;
		white-space: pre-line;
	}

}
section {
	&#back_profile {
	padding-top: 52px;
	padding-left: 30px;

	@media(min-width: 768px) {
		 padding-left: 0px;
		 padding-top: 91px; 
	}

	.main {

		a {
			font-size: 18px;
			font-weight: bold;
			color: #f47f04;
			text-decoration: none;
			font-family: 'Open Sans', sans-serif;

			&::before {
				content: "";
				margin-right: 10px;
				width: 24px;
				height: 20px;
				background-image: url("../assets/img/icon-set-esq.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				display: inline-block;
				vertical-align: bottom;
				margin-left: -5px;
				margin-right: 16px;
			}
		}
		@media(min-width: 768px) {
			flex-direction: row;
			margin: 0 auto;
		}
		@media(min-width: 1366px) {
			flex-direction: row;
			width: 100%;
			margin: 0 auto;
		}
	}
}
}
